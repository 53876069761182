import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import SEO from "../components/seo";
import TimeTable from "../components/timetable";
import schedule from '../data/class_schedule';
import kids_schedule from '../data/kids_schedule';
import { filterLessons } from '../tools/utils';

const OrchardHall = ({ data, location }) => {
  const lessons = filterLessons(
    [...schedule, ...kids_schedule],
    'orchard hall',
  );
  return (
    <>
      <SEO
        title="Orchard Hall"
        keywords={[`orchard hall`, `dance`, `lessons`, 'children', 'kids']}
        location={location}
      />
      <Link
        to="/lessons#schedule"
        className="hover:underline text-highlight font-semibold mb-4"
      >
        &larr;Back to Class Schedule
      </Link>
      <article className="flex flex-col items-center md:flex-row md:items-start md:justify-around">
        <div className="text-justify">
          <h2>Orchard Hall</h2>
          <p>
            Orchard Community Hall
            <br />
            Orchard Vale
            <br /> Longvernal
            <br /> Midsomer Norton
            <br /> BA3 2RA
          </p>
        </div>

        <figure className="mt-5 w-4/5 md:w-1/3 md:mt-0 self-center">
          <Image
            className="rounded"
            fluid={data.venue.sharp.fluid}
            alt="Orchard Hall"
          />
          <figcaption className="text-xs">
            An accessible hall with very good parking facilities.
          </figcaption>
        </figure>
      </article>
      <section className="md:mx-auto">
        <h2 className="mt-4">Class Times</h2>
        <TimeTable data={lessons} />
      </section>
    </>
  );
};

export const query = graphql`
  query {
    venue: file(relativePath: { eq: "orchard-hall.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default OrchardHall;